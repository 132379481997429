export default {
  namespaced: true,
  state: {
    menus: [],
    perms: [],
    rawMenus: [],
    isCollapsed: false,
  },
  mutations: {
    UPDATE_MENUS(state, m) {
      state.menus = m;
    },
    UPDATE_PERMS(state, p) {
      state.perms = p;
    },
    UPDATE_COLLAPSED(state) {
      state.isCollapsed = !state.isCollapsed;
    },
    UPDATE_RAW_MENUS(state, m) {
      state.rawMenus = m;
    },
  },
  actions: {
    updateRawMenus({ commit }, m) {
      commit("UPDATE_RAW_MENUS", m);
    },
    updateMenus({ commit }, m) {
      commit("UPDATE_MENUS", m);
    },
    updatePerms({ commit }, p) {
      commit("UPDATE_PERMS", p);
    },
    toggleSidebar({ commit }) {
      commit("UPDATE_COLLAPSED");
    },
  },
};
