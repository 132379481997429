import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import SvgIconPlugin from "@/assets/icons/SvgIconPlugin";

import "./permissions";
import "./axiosConfig";

import "./styles/index.less";

import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.less";

const app = createApp(App);

import * as antdIcons from "@ant-design/icons-vue";
Object.keys(antdIcons).forEach(key => {
  if (key !== "default") {
    app.component(key, antdIcons[key]);
  }
});
app.config.globalProperties.$antIcons = antdIcons;

app.directive("permission", {
  mounted(el, { value }) {
    const perms = store.getters.perms;
    if (!value || perms.indexOf(value) === -1) {
      el.parentNode && el.parentNode.removeChild(el);
    }
  },
});

// import "ant-design-vue/dist/antd.dark.less";
app.use(SvgIconPlugin).use(Antd).use(store).use(router).mount("#app");
