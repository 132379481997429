import router from "@/router";
import store from "@/store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

NProgress.configure({
  showSpinner: false,
});

const WHITE = ["/404"];

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  if (store.getters.token) {
    if (to.path === "/login") {
      NProgress.done();
      next({
        path: "/main",
        redirect: true,
      });
    } else {
      if (!store.getters.loadInfo) {
        let r = await store.dispatch("user/loadInfo");
        // 这种方式可以解决动态注册路由，路径匹配不到的情况
        if (r) {
          NProgress.done();
          next({
            ...to,
            replace: true,
          });
        } else {
          await store.dispatch("user/logout");
          location.reload();
        }
      } else {
        next();
      }
    }
  } else {
    if (WHITE.indexOf(to.path) > -1) {
      next();
    } else {
      if (to.path !== "/login") {
        next({
          path: "/login",
          replace: true,
        });
      } else {
        next();
      }
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
