<template>
  <a-config-provider :locale="locale">
    <a-spin
      :spinning="$store.state.spinner.showSpinner"
      :tip="$store.state.spinner.message"
      wrapper-class-name="spinnerClass"
    >
      <router-view />
    </a-spin>
  </a-config-provider>
</template>
<script type="text/javascript">
import zhCN from "ant-design-vue/es/locale/zh_CN";
import * as echarts from "echarts";
import { provide } from "vue";
export default {
  components: {},
  setup() {
    provide("ec", echarts);
  },
  data() {
    return {
      locale: zhCN,
    };
  },
};
</script>
<style>
.spinnerClass {
  width: 100%;
  height: 100%;
}
</style>
