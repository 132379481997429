import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "BasicLayout",
    component: () => import("@/layouts"),
    children: [
      {
        path: "/main",
        name: "Home",
        meta: {
          title: "首页",
        },
        component: () => import("@/views/Home"),
      },
      {
        path: "/vipCoupon",
        name: "VipCoupon",
        meta: {
          title: "会员优惠券",
        },
        component: () => import("@/views/Coupon/VIPCoupon/index"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login"),
  },
  {
    path: "/404",
    name: "NotFound",
    component: () => import("@/views/Error/NotFound"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
