import SvgIcon from "@/components/SvgIcon";

export default {
  install(vue) {
    const context = require.context("@/assets/icons/svg", false, /\.svg$/);
    context.keys().forEach(path => {
      const temp = path.match(/\.\/([A-Za-z0-9\-_]+)\.svg$/);
      if (!temp) return;
      // const name = temp[1];
      // require(`@/assets/icons/svg/${name}.svg`);
    });

    vue.component("svg-icon", SvgIcon);
  },
};
