import axios from "axios";
import store from "@/store";
import { removeToken } from "@/utils/tokenUtils";

axios.interceptors.request.use(config => {
  config.baseURL = "/frontApi";
  config.timeout = config.timeout || 15000;
  config.headers = {
    "Content-type": "application/json;charset=utf-8",
  };
  if (store.getters.token) {
    config.headers["Authorization"] = "Bearer " + store.getters.token;
  }
  return config;
});

axios.interceptors.response.use(
  async res => {
    // 二进制数据则直接返回
    if(res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer'){
      return Promise.resolve(res);
    }
    let data = res.data;
    if (data.result === "401" || data.result === "403") {
      removeToken();
      // 注销
      location.reload();
      return Promise.reject(null);
    } else if (data.result === "0") {
      return Promise.resolve(data);
    } else {
      return Promise.reject(new Error(data.message));
    }
  },
  async e => {
    return Promise.reject(new Error("系统异常，请联系管理员"));
  },
);
