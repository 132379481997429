export default {
  // 密码前缀
  pwdPrefix: "web-",

  parkingType: {
    PARING_CURD: 1, //路内
    PARKING_CLOSED: 2 //封闭
  },

  //支付状态 0:待支付,2:支付完成 3:待结算
  payStates: {
    WAITING_PAY: 0,
    OVER_PAY: 2,
    WAITING_SETTLE: 3
  },

  // 0-欠费记录 2-支付记录
  recordType: {
    OWE_RECORD: 0,
    PAY_RECORD: 2
  },
  //支付渠道:{0:微信,1:支付宝,2:银联,3:现金,4:其他
  payChannel: {
    weChat: 0,
    Alipay: 1,
    UnionPay: 2,
    cash: 3,
    other: 4
  },

  //-1:退款失败,0:待退款,1:退款中,2:退款成功
  refundStates: {
    REFUND_FAIL: -1,
    REFUND_WAITING: 0,
    REFUND_ON: 1,
    REFUND_OVER: 2
  },

  //订单类型{0:普通订单,1:错误免单,2:特殊车辆免单,3:免费时段}
  orderTypes: {
    common_order: 0,
    free_order: 1,
    special_order: 2,
    free_time_order: 3
  }
};
