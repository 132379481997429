import axios from "axios";

export const login = (account, password) => {
  return axios.get("/login", {
    params: {
      account,
      password,
    },
  });
};

export const userInfo = () => {
  return axios.get("/userInfo");
};
