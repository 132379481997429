export default {
  namespaced: true,
  state: {
    showSpinner: false,
    message: "",
  },
  mutations: {
    TOGGLE_SPINNER(state) {
      state.showSpinner = !state.showSpinner;
    },
    UPDATE_TIP_MSG(state, m) {
      state.message = m;
    },
  },
  actions: {
    toggleSpinner({ commit }) {
      commit("TOGGLE_SPINNER");
    },
    updateTipMsg({ commit }, msg) {
      commit("UPDATE_TIP_MSG", msg);
    },
  },
};
