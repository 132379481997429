import { createStore } from "vuex";

import user from "@/store/modules/user";
import sidebar from "@/store/modules/sidebar";
import spinner from "@/store/modules/spinner";

export default createStore({
  getters: {
    token: state => state.user.token,
    loadInfo: state => state.user.loadInfo,
    perms: state => state.sidebar.perms,
  },
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    sidebar,
    spinner,
  },
});
