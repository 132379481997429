import { getToken, removeToken, setToken } from "@/utils/tokenUtils";
import { repackageRouter, wrapMenuTree } from "@/utils/routerUtils";
import router from "@/router";
import store from "@/store";
import md5 from "js-md5";
import config from "@/config";
import { login, userInfo } from "@/api/authApi";

export default {
  namespaced: true,
  state: {
    token: getToken(),
    id: "",
    name: "",
    loadInfo: false,
    menus: [],
    perms: [],
  },
  mutations: {
    UPDATE_LOADINFO(state) {
      state.loadInfo = true;
    },
    UPDATE_TOKEN(state, t) {
      state.token = t;
      setToken(t);
    },
    REMOVE_TOKEN(state) {
      state.token = "";
      removeToken();
    },
    UPDATE_NAME(state, n) {
      state.name = n;
    },
  },
  actions: {
    logout({ commit }) {
      commit("REMOVE_TOKEN");
    },
    async doLogin({ commit }, { account, password }) {
      try {
        let pwd = md5(config.pwdPrefix + password);
        let { data } = await login(account, pwd);
        commit("UPDATE_TOKEN", data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async loadInfo({ commit }) {
      let menuList;
      try {
        let { data } = await userInfo();
        menuList = data.menus.map(item => {
          return {
            ...item,
            key: item.id,
          };
        });
        commit("UPDATE_NAME", data.name);
      } catch (e) {
        return Promise.resolve(false);
      }
      // 保存原始菜单列表, 在所有的数据上加上key字段，方便转换成tree的数据
      await store.dispatch("sidebar/updateRawMenus", menuList);
      // 生成菜单的列表
      let menus = wrapMenuTree(menuList.filter(item => item.type === 1));
      await store.dispatch("sidebar/updateMenus", menus);
      await store.dispatch(
        "sidebar/updatePerms",
        menuList.filter(item => item.type === 2).map(item => item.perm),
      );
      repackageRouter(null, menus);
      router.addRoute({
        path: "/:catchAll(.*)",
        redirect: "/404",
      });
      commit("UPDATE_LOADINFO");
      return Promise.resolve(true);
    },
  },
};
