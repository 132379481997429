import router from "@/router";
import store from "@/store";

export const wrapMenuTree = list => {
  return list
    .filter(item => !item.parentId)
    .sort((a, b) => a.orderNum - b.orderNum)
    .map(item => {
      return {
        ...item,
        children: menuChildren(item.id, list),
      };
    });
};

const menuChildren = (parentId, list) => {
  let tmp = list.filter(item => item.parentId === parentId);
  if (tmp.length === 0) {
    return [];
  }
  return tmp
    .sort((a, b) => a.orderNum - b.orderNum)
    .map(item => {
      return {
        ...item,
        children: menuChildren(item.id, list),
      };
    });
};

export const repackageRouter = (parentName, menus) => {
  menus.forEach(item => {
    if (item.component !== "Layout") {
      const meta = JSON.parse(item.meta || "{}");
      meta.title = item.title;
      router.addRoute("BasicLayout", {
        path: item.path,
        name: item.name,
        meta: meta,
        component: () => import(`@/${item.component}`),
      });
    }
    if (item.children && item.children.length > 0) {
      repackageRouter(item.name, item.children);
    }
  });
};

/**
 * 根据请求路径获取所有父节点
 * @param path
 */
export const getParentPath = (path, list = []) => {
  const menus = store.state.sidebar.rawMenus.filter(item => item.type === 1);
  let m = menus.filter(item => item.path === path);
  if (m.length === 0 || !m[0].parentId) {
    return;
  }
  let parent = menus.filter(item => item.id === m[0].parentId);
  if (!parent) {
    return;
  }
  list.push(parent[0].path);
  return getParentPath(parent[0].path, list);
};
