const KEY = "cp:front:token";

export const getToken = () => {
  return sessionStorage.getItem(KEY) || "";
};

export const setToken = token => {
  sessionStorage.setItem(KEY, token);
};

export const removeToken = () => {
  sessionStorage.removeItem(KEY);
};
